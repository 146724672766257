import axios from 'axios'

export default () => {
    axios.create({ baseURL: process.env.GATSBY_API_URL });
    axios.interceptors.request.use(req => {
        //if the request is too long and hve blank page till render all it discoment this lines and the loader will be shown
        let loader = document.querySelector('.js-loader');
        if (loader) loader.classList.add('display-flex-imp');
        return req;
    });
    axios.interceptors.response.use(res => {
        setTimeout(() => {
            let loader = document.querySelector('.js-loader');
            if (res && loader) loader.classList.remove('display-flex-imp');
        }, 150);
        return res;
    }, err => {
        let loader = document.querySelector('.js-loader');
        if (err && loader) loader.classList.remove('display-flex-imp');
        return err;
    });
    return axios;
}