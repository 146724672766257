import React from 'react';

const Banner = (props) => {
  const { text, subtitle = null, tam = 60 } = props;
  return (
    <section className={`banner banner-${tam}`}>
      <div className="banner-text">{text}</div>
      {subtitle ? <p className="banner-subtitle">{subtitle}</p> : null}
    </section>
  )
}

export default Banner;