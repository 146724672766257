import React, { useState, useEffect } from 'react';
import client from "../../../components/utils/client";
import icLogo from "../../../components/assets/img/pages/produtos/ic-logo.png";
import Intent from "../../../components/common/link";

const SecondGrid = () => {  
  const [allData, setAllData] = useState({});

  useEffect(() => {
    const handleGetGridData = () => {
      client()
        .get("content-group/8")
        .then(res => {
          let data = res.data || [];
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          if(data.Contents) {
            let list = [];                                 
            for(let i = 1; i <= 7; i++) {
              let title = data.Contents.find(item => item.Key === `home-areas-card-${i}-title`);              
              let image = data.Contents.find(item => item.Key === `home-areas-card-${i}-image`);
              let obj = {
                title: title ? title.Value : "Undefined",                
                image: image ? image.Value : icLogo,
              }
              list.push(obj);
            }
            setAllData(allData => ({
              ...allData,
              list,
            }));
          } 
        })
        .catch(err => {
          console.log(err);
        })
    }

    const handleGetOtherData = () => {
      client()
        .get("content-group/11")
        .then(res => {
          let data = res.data || [];
          if(data.Contents) {
            let label = data.Contents.find(item => item.Key === "tecnologia-secondary-label");
            let title = data.Contents.find(item => item.Key === "tecnologia-secondary-title");
      
            setAllData(allData => ({
              ...allData,
              label: label ? label.Value : "ZL EZATA",
              title: title ? title.Value : "Undefined",
            }))
          }
        })
    }

    handleGetGridData(); 
    handleGetOtherData();  
  }, [])

  return (
    <section className="container-60 margin-vertical-100px tecnologia__areas">
      <h1 className="areas__title">{allData.title}</h1>
      <div className="def__label__box">
        <div className="rectangle"></div>
        <p className="label">{allData.label}</p>                           
      </div>
      <div className="wrapper">
        {allData.list ? allData.list.map((item, index) => (
          <Intent to={`tecnologia/${item.title.toLowerCase()}`}>
            <span key={index} className="img__box" style={{background: `url(${item.image}) no-repeat center/cover`}}>
              <h1 className="title">{item.title}</h1>
            </span>
          </Intent>
        )) : null}        
      </div>  
    </section>  
  )
}
export default SecondGrid;