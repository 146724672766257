import React, { useState } from 'react';
import img1 from "../../../../static/images/freedom.png";
import img2 from "../../../../static/images/goldenrose.png";
import { AppBar, Tabs, Tab } from "@material-ui/core"
import TabPanel from "../../common/TabPanel";
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  tabBar: {
    background: "white",
    borderTop: "1px solid lightgrey", 
  },
  tab: {
    flex: 1,
    color: "black",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
}));
const TabsComponent = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <AppBar className={classes.tabBar} position="static" elevation={0}>
        <Tabs value={value} onChange={handleChange}>
        <Tab className={classes.tab} label="Todos" {...a11yProps(0)} />
        <Tab className={classes.tab} label="Agrícola" {...a11yProps(1)} />
        <Tab className={classes.tab} label="Industrial" {...a11yProps(2)} />
        <Tab className={classes.tab} label="Transporte" {...a11yProps(3)} />
        <Tab className={classes.tab} label="Energia Solar" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel className="tab__item" value={value} index={0}>
        <div className="subpage-wrapper-5">
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
        </div>
      </TabPanel>
      <TabPanel className="tab__item" value={value} index={1}>
        <div className="subpage-wrapper-5">
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
        </div>
      </TabPanel>
      <TabPanel className="tab__item" value={value} index={2}>
        <div className="subpage-wrapper-5">
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
        </div>
      </TabPanel>
      <TabPanel className="tab__item" value={value} index={3}>
        <div className="subpage-wrapper-5">
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
          <div><img src={img2} className="img" alt="corte"/></div>
        </div>
      </TabPanel>
      <TabPanel className="tab__item" value={value} index={4}>
        <div className="subpage-wrapper-5">
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
          <div><img src={img1} className="img" alt="corte"/></div>
        </div>
      </TabPanel>      
    </>
  )
}

export default TabsComponent;