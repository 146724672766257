import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import SubPage from "./comps/SubPage";
  
const Solda = () => {
  return (
    <Layout infoPage={{page: "tecnologia", sub: "solda"}}>
      <SubPage name="Solda" />
    </Layout>
  )
}

export default Solda;