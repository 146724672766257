import React, { useState, useEffect } from 'react';

// comps
import Banner from "../../common/Banner";
import client from "../../../components/utils/client";
import TabsComponent from "./TabsComponent";
import SecondGrid from "./SecondGrid";

const SubPage = (props) => {
  const { name } = props;
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    const handleGetData = () => {
      client()
        .get("content-group/11")
        .then(res => {
          let data = res.data || [];
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          if(data.Contents) {
            data = data.Contents.filter(item => item.Key.includes(name.toLowerCase()));
            let title = data.find(item => item.Key.includes("page-title"));
            let label = data.find(item => item.Key.includes("page-label"));
            setAllData(allData => ({
              ...allData,
              page: {
                title: title ? title.Value : "Undefined",
                label: label ? label.Value : "Undefined",                
              },
              main: data.filter(item => item.Key.includes("main") || item.Key.includes("secondary")),
            }));
          }          
        })
        .catch(err => {
          console.log(err);
        })
    }

    handleGetData();
  }, [name])
    
  const handleClick = (e, i) => {
    let item = document.getElementById(`item-${i}`);
    let list = document.querySelectorAll(".list .item");
    list.forEach(obj => {
      obj.classList.remove("open");
    });    
    item.classList.add("open")
  }

  return (
    <div className="subpage">
      {allData ? (<>
        <Banner text={name} />
        <section className="container-60 margin-vertical-50px">
          <div className="def__label__box">
            <div className="rectangle"></div>
            <p className="label">{allData.page.label}</p>
          </div>          
          <span className="flex-info">
            <div className="left-info">              
              {allData.main ? allData.main.map((item, index) => {
                if(item.Key.includes("-title")) {
                  return <h1 key={index} className="subtitle">{item.Value}</h1>
                }
                else if(item.Key.includes("-subtitle")) {
                  return <h4 key={index}>{item.Value}</h4>
                }
                else if(item.Key.includes("-items")) {
                  return (
                    <ul key={index}>
                      {item.Value.split(";").map((el, i) => (
                        <li key={i}>{el}</li>
                      ))}
                    </ul>
                  )
                }
                else {
                  return <p key={index}>{item.Value}</p>                  
                }
              }) : null}                           
            </div>
            {/* <div className="right-info">
              <div className="list">
                <h4>MATERIAIS</h4>              
                {dataAll.map((item, i) => (
                  <div className="item" id={`item-${i}`} key={i}>
                    <h4 className="material-name">{item.Name}</h4>
                    <div className="img-box" onClick={(e) => handleClick(e, i)} onKeyDown={(e) => handleClick(e, i)} role="button" tabIndex={-1}>
                      <img src={item.Icon} alt="icon"/>
                    </div>
                  </div>
                ))}                
              </div>
            </div> */}
          </span>
        </section>
        <section className="container-60 margin-vertical-100px">
          <div className="def__label__box center margin-vertical-50px">
            <div className="rectangle"></div>
            <p className="label">APLICAÇÕES</p>
            <div className="rectangle"></div>
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <TabsComponent />
            <button className="primary-button reverse" style={{padding: 10, paddingTop: 3, paddingBottom: 3}}>VER MAIS</button>
          </div> 
        </section>      
        <SecondGrid />  
      </>) : null}
    </div>
  )
}

export default SubPage;