import React, { useState, useEffect } from "react";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import "../assets/scss/_main.scss";

import icoLogo from '../assets/img/ic-logo-ico.png';
import CircularProgress from '@material-ui/core/CircularProgress';

const windowGlobal = typeof window !== 'undefined' && window;
const Layout = (props) => {
    
    const [size, setSize] = useState(1920);

    useEffect(() => {
        setSize(windowGlobal.innerWidth);
        const handleResize = () => {            
            setSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
    }, [])

    const { children, infoPage } = props
    return (
        <>
            <div className="loader js-loader display-none">
                <CircularProgress
                    size={80}
                    thickness={4}
                    color="inherit"
                />
                <img src={icoLogo} alt="logo" />
            </div>
            <div className="main">
                <Header infoPage={infoPage} smallScreen={size <= 840} />
                <div>{children}</div>
                <Footer />
            </div>
        </>
    )
}

export default Layout
